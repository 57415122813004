import { Injectable } from '@angular/core';

export type CreditCardTypeCardBrandName =
  | 'VISA'
  | 'AMEX'
  | 'MASTERCARD'
  | 'DISCOVER'
  | 'DINERS'
  | 'JCB'
  | 'UNIONPAY'
  | 'MAESTRO'
  | 'MIR';

export enum CreditCardType {
  VISA = 'VISA',
  AMEX = 'AMEX',
  MASTERCARD = 'MASTERCARD',
  DISCOVER = 'DISCOVER',
  DINERS = 'DINERS',
  JCB = 'JCB',
  UNIONPAY = 'UNIONPAY',
  MAESTRO = 'MAESTRO',
  MIR = 'MIR',
}

@Injectable({
  providedIn: 'root'
})
export class CreditCardTypeService {

  constructor() {
  }

  private _re = {
    // starts with 34/37; 15 digits
    'AMEX': /^3[47]\d{0,13}/,

    // starts with 6011/65/644-649; 16 digits
    'DISCOVER': /^(?:6011|65\d{0,2}|64[4-9]\d?)\d{0,12}/,

    // starts with 300-305/309 or 36/38/39; 14 digits
    'DINERS': /^3(?:0([0-5]|9)|[689]\d?)\d{0,11}/,

    // starts with 51-55/2221–2720; 16 digits
    'MASTERCARD': /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}/,

    // starts with 2131/1800/35; 16 digits
    'JCB': /^(?:35\d{0,2})\d{0,12}/,

    // starts with 50/56-58/6304/67; 16 digits
    'MAESTRO': /^(?:5[0678]\d{0,2}|6304|67\d{0,2})\d{0,12}/,

    // starts with 22; 16 digits
    'MIR': /^220[0-4]\d{0,12}/,

    // starts with 4; 16 digits
    'VISA': /^4[0-9]{12}(?:[0-9]{3})?$/,

    // starts with 62/81; 16 digits
    'UNIONPAY': /^(62|81)\d{0,14}/
  };

  /**
   * Check Credir Card type by CC number
   * @param credirCardNumber
   */
  creditCardType(cc: any): CreditCardTypeCardBrandName | string {
    if (cc && typeof cc === 'number') {
      cc = cc.toString();
    }
    return Object.keys(this._re).find(key => this._re[key].test(cc)) || null;
  }

  /**
   * Get credit card pattern for type
   * @param cardType
   */
  public getCardPattern(cardType: CreditCardTypeCardBrandName): RegExp {
    return this._re[cardType];
  }

}
